import * as React from 'react'

export function CheckCircle(props) {
  return (
    <svg viewBox="0 0 32 32" fill="none" {...props}>
      <circle cx={16} cy={16} r={16} fill="currentColor" />
      <path
        d="M22.932 10.602a.8.8 0 01.067 1.13l-8.534 9.6a.8.8 0 01-1.195 0l-4.267-4.8a.8.8 0 011.196-1.064l3.668 4.128 7.936-8.928a.8.8 0 011.13-.066z"
        fill="#fff"
      />
    </svg>
  )
}

const MemoCheckCircle = React.memo(CheckCircle)
export default MemoCheckCircle
