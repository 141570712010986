import React, { useState } from 'react'

export default function Button({
  onClick = () => undefined,
  disabled,
  loading = false,
  className,
  fullWidth = true,
  children,
  ...props
}) {
  const { theme, label } = props
  let buttonTheme
  switch (theme) {
    case 'small-white':
      buttonTheme =
        'p-2 md:px-10 md:py-4 bg-white border-white text-neutral-800 text-caption-fluid'
      break
    case 'small-orange':
      buttonTheme =
        'p-2 md:px-10 md:py-4 bg-orange-700 border-orange-500 text-white text-caption-fluid'
      break
    case 'small':
      buttonTheme =
        'p-5 md:px-5 md:py-12 text-caption-fluid bg-cta-primary  border-cta-primary text-white'
      break
    case 'small-transparent':
      buttonTheme =
        'px-5 py-8 md:px-5 md:py-12 text-caption-fluid bg-transparent border-white text-white w-auto'
      break
    case 'secondary':
      buttonTheme =
        'bg-cta-secondary border-neutral-800 text-neutral-800 hover:bg-neutral-100'
      break
    case 'transparent':
      buttonTheme =
        'bg-transparent border-white text-white hover:bg-neutral-100 hover:text-neutral-800'
      break
    case 'purple':
      buttonTheme = 'bg-purple-800 border-purple-800 text-white'
      break
    default:
      buttonTheme = `hover:bg-orange-800 durarion-300 ${
        disabled
          ? 'cursor-not-allowed bg-neutral-200 text-neutral-400 border-neutral-200'
          : 'bg-cta-primary border-cta-primary text-white'
      }
      ${loading && 'cursor-not-allowed'}
      `
      break
  }

  const disabledTheme =
    disabled && 'disabled:opacity-50 disabled:cursor-not-allowed'
  return (
    <button
      className={[
        `block text-center rounded-lg  px-10 md:px-24 py-14 border duration-100 transition-all relative ${
          fullWidth ? 'w-full mx-auto px-10 md:px-24' : 'w-auto px-24'
        } ${buttonTheme} ${disabledTheme}`,
        className
      ]
        .join(' ')
        .trim()}
      onClick={onClick}
      disabled={disabled || loading}
      {...props}
    >
      {loading && (
        <>
          <span className="invisible">loading</span>
          <div className="inset-0 absolute w-full h-full z-10 rounded-lg bg-opacity-50 pointer-events-none bg-white">
            <svg
              className="absolute inset-0 m-auto animate-spin"
              width="30"
              height="30"
              viewBox="0 0 120 120"
              fill="none"
            >
              <path
                d="M100.305 19.6949C109.63 29.0197 115.433 41.2893 116.726 54.413C118.018 67.5368 114.72 80.7027 107.394 91.6675C100.067 102.632 89.1656 110.718 76.5462 114.546C63.9268 118.374 50.3705 117.708 38.187 112.661C26.0036 107.615 15.9469 98.4997 9.73049 86.8696C3.51406 75.2395 1.52253 61.8137 4.09524 48.8798C6.66795 35.946 13.6457 24.3043 23.8396 15.9384C34.0335 7.5725 46.8128 3 60 3"
                stroke="#ffffff"
                strokeWidth="6"
                strokeLinecap="round"
              />
            </svg>
          </div>
        </>
      )}
      <>
        {label}
        {children}
      </>
    </button>
  )
}
