import Input from '~/components/form/Input.js'
import Button from '~/components/Button.js'
import Link from 'next/link'
import Body from '~/components/typography/Body'
import { useRouter } from 'next/router'

const LoginForm = ({
  checkLogin,
  updateEmail,
  errors,
  updatePassword,
  email,
  password
}) => {
  const router = useRouter()
  return (
    <form onSubmit={checkLogin}>
      <Input
        onChange={updateEmail}
        type="email"
        label="Email"
        error={errors.email ?? ''}
        value={email}
        placeholder="Your email"
      />
      <Input
        autoComplete="off"
        type="password"
        onChange={updatePassword}
        label="Password"
        value={password}
        error={errors.password ?? ''}
        placeholder="Your password"
      />
      <div className="md:flex justify-center items-center">
        <Button className="md:mx-10" onClick={checkLogin} label="Login" />
        <Button
          className="mt-20 md:mt-0 md:mx-10"
          theme="secondary"
          onClick={(e) => {
            e.preventDefault()
            router.push('/signup')
          }}
          label="Sign up"
        />
      </div>
      {errors.submit && (
        <Body className="mt-10 text-sm text-red-500">
          {errors.submit}, please try again
        </Body>
      )}
      <Link href="/password-reset/request">
        <a className="text-caption-fluid mt-20 block text-center">
          Forgotten your password?
        </a>
      </Link>
    </form>
  )
}

export default LoginForm
