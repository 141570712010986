import React, { forwardRef } from 'react'

export const inputThemes = {
  default: {
    className:
      'py-15 px-20 w-full rounded-md border border-neutral-200 placeholder-neutral-400',
    wrapperClassName: 'mb-20',
    labelClassName: 'block mb-10',
    errorClassName: 'text-sm-fluid text-red-600 mt-5'
  },
  quizBuilder: {
    className:
      'px-16 py-12 w-full text-neutral-800 leading-5 rounded-md border border-neutral-200 focus:outline-none focus:ring-neutral-500 focus:border-neutral-500',

    wrapperClassName: 'form-group mb-20',
    labelClassName: 'block mb-8',
    errorClassName: 'text-sm-fluid text-red-600 mt-5'
  }
}

const Input = forwardRef(
  (
    {
      label,
      info,
      error,
      className = inputThemes.default.className,
      wrapperClassName = inputThemes.default.wrapperClassName,
      labelClassName = inputThemes.default.labelClassName,
      errorClassName = inputThemes.default.errorClassName,
      iconLeft,
      iconRight,
      ...props
    },
    ref
  ) => (
    <div className={wrapperClassName}>
      <label>
        {label && <span className={labelClassName}>{label}</span>}
        {info && (
          <span className="-mt-10 mb-5 flex text-xs text-neutral-500">
            {info}
          </span>
        )}
        <div className="relative">
          {iconLeft}
          {props.type === 'textarea' ? (
            <textarea className={className} ref={ref} {...props} />
          ) : (
            <>
              <input
                className={className + (props.maxLength ? ' pr-80' : '')}
                ref={ref}
                {...props}
              />
              {props.maxLength && props.value.length >= props.maxLength - 50 && (
                <div className="absolute top-16 right-12">
                  <p className="text-sm-fluid text-neutral-500">
                    {props.value.length}/{props.maxLength}
                  </p>
                </div>
              )}
            </>
          )}
          {iconRight}
        </div>
        {error && (
          <div>
            {error.map((msg) => (
              <p className={errorClassName} key={msg}>
                {msg}
              </p>
            ))}
          </div>
        )}
      </label>
    </div>
  )
)

export default Input
