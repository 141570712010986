import axios from 'axios'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import Background from '../components/Background'
import Button from '../components/Button.js'
import Input from '../components/form/Input.js'
import OverlayContent from '../components/OverlayContent'
import { useAuth } from '../context/AuthContext'
import Head from 'next/head'
import Link from 'next/link'
import DialogPopup from '~/components/DialogPopup'
import Body from '~/components/typography/Body'
import HubSpot from '~/components/HubSpot'
import { useTrackingCode } from 'react-hubspot-tracking-code-hook'
import LoginForm from '~/components/login/LoginForm'

export default function Index(props) {
  const [version, setVersion] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [errors, setErrors] = useState([])
  const [loadingBlock, setLoadingBlock] = useState(false)
  const { dispatch } = useAuth()
  const [cookies, setCookie] = useCookies(['auth_token', 'user_name', 'pin'])

  const router = useRouter()
  const { fromJoin } = router.query

  const { setIdentity } = useTrackingCode()
  const _hsq = typeof window !== 'undefined' && window._hsq ? window._hsq : []

  useEffect(() => {
    if (router.query.email) {
      setEmail(router.query.email)
    }
  }, [router.isReady])

  useEffect(() => {
    axios
      .get(`${process.env.base_api}/version`, {})
      .then(function (response) {
        setVersion(response.data.version)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [])

  const [showLegacyPopup, setShowLegacyPopup] = useState(false)

  const checkLogin = (e) => {
    e.preventDefault()
    axios
      .post(`${process.env.base_api}/auth/token`, {
        device_name: 'website',
        email: email,
        password: password
      })
      .then(function (response) {
        setErrors({})
        if (response.data.migrated_user) {
          setShowLegacyPopup(true)
        } else {
          dispatch({
            type: 'login',
            auth_token: response.data.token.auth_token
          })
          setIdentity(email)

          setCookie('auth_token', response.data.token.auth_token, { path: '/' })

          if (cookies.pin && fromJoin) router.back()

          router.push('/dashboard')
        }
      })
      .catch(function (error) {
        if (error.response) {
          const { data } = error.response
          setErrors(data.errors)
        } else {
          setLoadingBlock(false)
          if (error.message) {
            setErrors({ submit: [error.message] })
          }
        }
      })
  }
  const updateEmail = (evt) => {
    if (loadingBlock) {
      setLoadingBlock(false)
    }
    setEmail(evt.target.value)
  }
  const updatePassword = (evt) => {
    if (loadingBlock) {
      setLoadingBlock(false)
    }
    setPassword(evt.target.value)
  }

  return (
    <Background>
      <Head>
        <title>Login | KwizzBit</title>
      </Head>
      <DialogPopup
        openModal={() => setShowLegacyPopup(true)}
        closeModal={() => setShowLegacyPopup(false)}
        isOpen={showLegacyPopup}
      >
        <Body large className="mb-20">
          It looks like you already have an account on our old system. You'll
          need to reset your password to keep kwizzing. We've sent you an email
          - please check your inbox.
        </Body>
        <Button onClick={() => setShowLegacyPopup(false)}>Close</Button>
      </DialogPopup>
      <div className="flex flex-col justify-end md:justify-center flex-1 h-full">
        <OverlayContent>
          <LoginForm
            checkLogin={checkLogin}
            updateEmail={updateEmail}
            updatePassword={updatePassword}
            errors={errors}
            email={email}
            password={password}
            loadingBlock={loadingBlock}
          />
        </OverlayContent>
        <Link href="/release-notes">
          <a className="text-caption-fluid mt-14 block text-center text-white">
            {version}
          </a>
        </Link>
      </div>
      <HubSpot page={'login/'} />
    </Background>
  )
}
